@import "./fonts";

@tailwind base;
@layer base {
  :focus-visible {
    outline: 1px solid theme(colors.black);
  }
  input:focus-visible,
  textarea:focus-visible,
  button:focus-visible {
    outline: none !important;
  }
  @import "./base/reset";
  @import "./base/wysiwyg";
  @import "./base/dev";
  @import "./base/sticky-pages";
  @import "./base/scrollbars";
}

@tailwind utilities;
@layer utilities {
  /* LAYOUT LIMITED WIDTHS */
  .max-content {
    @apply mx-auto md:max-w-contentMd lg:max-w-contentLg xl:max-w-contentXl;
  }
  .max-content-narrow {
    @apply mx-auto max-w-contentSm;
  }
  .max-content-wider {
    @apply mx-auto md:max-w-contentMd lg:max-w-contentLg xl:max-w-contentXl 2xl:max-w-widerContentXl;
  }

  /* SVG FILL COLORS */
  .svg-primary-primary {
    @apply fill-primary;
  }
  .svg-success {
    @apply fill-success;
  }
  .svg-error {
    @apply fill-error;
  }
  .svg-warning {
    @apply fill-warning;
  }
  .svg-info {
    @apply fill-blue;
  }

  /* COMMON ICO STYLES */
  .ico {
    @apply inline-block transition-slow;
  }
  .ico--lg {
    @apply w-[8.9rem] h-[8.9rem] mb-12 fill-secondary;
  }

  .ico--header {
    @apply fill-black w-[2.8rem] h-[2.8rem];

    &:hover,
    &:focus {
      @apply fill-gray-70;
    }
  }

  /* CUSTOM CLASSES */
  /* --- hide scrollbar --- */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* --- fading out widget --- */
  .fading-box {
    &:after {
      content: '';
      height: 100%;
      width: 3rem;
      position: absolute;
      top: 0;
      right: 0;
      pointer-events: none;
      background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #ffffff 100%);
    }
  }
  .fading-box--param-table {
    &:after {
      content: '';
      height: 3rem;
      width: 100%;
      position: absolute;
      bottom: 3.1rem;
      left: 0;
      right: 0;
      pointer-events: none;
      background: linear-gradient(rgba(0, 0, 0, 0) 0%, #ffffff 100%);
    }
  }

  /* --- Loader --- */
  .loader {
    @apply fill-blue animate-spin;
  }

  /* RADIUS */
  /*  --- common radius --- */
  .radius-common {
    @apply rounded-full;
  }
  /* --- checkbox radius --- */
  .radius-checkbox {
    @apply rounded-lg;
  }
  /* --- radio radius --- */
  .radius-radio {
    @apply rounded-full;
  }
  /* --- checkbox tile radius --- */
  .radius-tile {
    @apply rounded-lg;
  }
  /* --- textarea radius ---  */
  .radius-textarea {
    @apply rounded-3xl;
  }
  /* --- input/button radius --- */
  .radius-form {
    @apply rounded-full;
  }

  /* BORDERS */
  /* --- input/textarea/button/select border --- */
  .border-form {
    @apply border border-gray-30 focus-within:border-gray-60;
  }

  /* --- checkbox border --- */
  .border-checkbox {
    @apply border border-gray-30 focus-within:border-gray-60 focus:border-gray-60 checked:border-black checked:focus-within:border-black checked:focus:border-black enabled:group-hover/checkbox:border-gray-60 checked:group-hover/checkbox:border-black;
  }

  /* --- radio border --- */
  .border-radio {
    @apply border border-gray-30 focus-within:border-gray-60 focus:border-gray-60 checked:border-black checked:focus-within:border-black checked:focus:border-black enabled:group-hover/radio:border-gray-60 checked:group-hover/radio:border-black;
  }

  /* COMMON PADDINGS */
  /* --- input/textarea padding ---  */
  .p-form {
    @apply px-4 md:px-10 py-4;
  }

  /* --- select padding ---  */
  .p-form-select {
    @apply pl-10 pr-8 py-4;
  }

  /* TEXT PROPERTIES */
  /* --- input/textarea/select text properties --- */
  .text-form {
    @apply text-sm text-gray-70 disabled:text-gray-30;
  }

  /* COMMON TEXT SHADOW */
  .text-shadow-common {
    text-shadow: 1px 0 0 currentColor;
  }

  /* TRANSITIONS */
  /* --- common transition --- */
  .transition-common {
    @apply transition-all;
  }

  /* --- common slow transition --- */
  .transition-slow {
    @apply transition-all duration-500;
  }

  /* --- popup components transition --- */
  .transition-popup {
    @apply transition duration-100 ease-out transform scale-50 opacity-0 z-0;
  }
  .transition-popup-from {
    @apply transform scale-50 opacity-0 z-0;
  }
  .transition-popup-to,
  .transition-popup[data-status='open'] {
    @apply transform scale-100 opacity-100 z-40 absolute;
  }

  /* --- filter dropdown component transition --- */
  .transition-filter-dropdown {
    @apply transition duration-75 ease-out;
  }
  .transition-filter-dropdown-from {
    @apply transform opacity-0 z-0 absolute translate-y-8;
  }
  .transition-filter-dropdown-to {
    @apply transform opacity-100 z-40 absolute translate-y-0;
  }

  /* OVERLAYS */
  /* --- common overlay --- */
  .overlay-common {
    @apply w-screen h-screen fixed inset-0 bg-overlay-bg pointer-events-none;
  }
  /* --- transparent overlay --- */
  .overlay-transparent {
    @apply w-screen h-screen fixed inset-0;
  }

  /* LISTS */

  /* --- common list --- */
  .list-common {
    list-style-position: inside;
    list-style-image: url('../public/img/list.svg');
  }

  /* PAGINATION BULLET */
  .pagination-bullet {
    @apply inline-block shrink-0 bg-gray-10 w-5 h-5 rounded-full transition-common hover:bg-gray-30 cursor-pointer;
  }
  .pagination-bullet-active {
    @apply inline-block shrink-0 bg-gray-60 w-5 h-5 rounded-full pointer-events-none;
  }

  /* AUTOCOMPLETE */
  .is-open, .is-open.is-active {
    .fulltext-autocomplete__query {
      @apply fixed;
      @screen md {
        @apply static;
      }
    }
  }
}
@tailwind components;

@layer components {
  /* CONTAINER WIDTHS */
  .container {
    @apply max-w-full;
  }
  @screen sm {
    .container {
      max-width: theme('screens.xsm');
    }
  }
  @screen md {
    .container {
      max-width: 720px;
    }
  }
  @screen lg {
    .container {
      max-width: 960px;
    }
  }
  @screen xl {
    .container {
      max-width: 1140px;
    }
  }
  @screen 2xl {
    .container {
      max-width: theme('screens.2xl');
    }
  }

  /* NAV BUTTONS */
  .nav-button {
    @apply absolute rounded-full p-6;
    z-index: 5;
    .ico {
      @apply w-[1.3rem] h-[1.3rem];
    }
  }

  .nav-button--gallery {
    @apply top-1/2 transform -translate-y-1/2 focus-within:!border-gray-10;
    &.nav-button--left {
      @apply left-4;
    }
    &.nav-button--right {
      @apply right-4;
    }
  }

  .nav-button--product-tile,
  .nav-button--product-tile-modal,
  .nav-button--products {
    @apply absolute sm:rounded-full sm:p-6;
    .ico {
      @apply w-[1.3rem] h-[1.3rem];
    }
    &.nav-button--left {
      @apply left-0 -ml-6 rounded-r-full rounded-l-none pt-6 pr-4 pb-6 pl-2 sm:p-6 sm:rounded-full sm:ml-0;
    }
    &.nav-button--right {
      @apply right-0 -mr-6 rounded-r-none rounded-l-full pt-6 pr-2 pb-6 pl-4 sm:p-6 sm:rounded-full sm:mr-0;
    }
  }
  .nav-button--products {
    @apply top-1/2 transform -translate-y-1/2 focus-within:!border-gray-10;
  }
  .nav-button--product-tile {
    @apply top-[calc((100vw_/_2_-_4.5rem)_/_2)] sm:top-[7.8rem] md:top-[7.2rem] lg:top-[10.2rem] xl:top-[9.8rem] 2xl:top-[12.8rem];
  }
  .nav-button--product-tile-modal {
    @apply top-[calc((100vw_/_2_-_4.5rem)_/_2)] sm:top-[10.9rem] disabled:hidden hidden sm:block;
  }

  /* ACCORDION FIX */
  .accordion__detail {
    &[open] {
      .ico--minus {
        @apply block;
      }
      .ico--plus {
        @apply hidden;
      }
    }
  }

  /* PASSWORD STRENGTH PROGRESSBAR */
  /*  --- Progressbar "grid" ---  */
  .password-strength__meter-container {
    &:after {
      background: url("data:image/svg+xml,%3Csvg width='2' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M-1-1h12v414H-1z'/%3E%3Cg/%3E%3C/svg%3E")
      16.6% 0 no-repeat,
      url("data:image/svg+xml,%3Csvg width='2' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M-1-1h12v414H-1z'/%3E%3Cg/%3E%3C/svg%3E")
      33.3% 0 no-repeat,
      url("data:image/svg+xml,%3Csvg width='2' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M-1-1h12v414H-1z'/%3E%3Cg/%3E%3C/svg%3E")
      50% 0 no-repeat,
      url("data:image/svg+xml,%3Csvg width='2' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M-1-1h12v414H-1z'/%3E%3Cg/%3E%3C/svg%3E")
      66.6% 0 no-repeat,
      url("data:image/svg+xml,%3Csvg width='2' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M-1-1h12v414H-1z'/%3E%3Cg/%3E%3C/svg%3E")
      83.3% 0 no-repeat;
    }
  }
  .cart-confirmation__box--highlight .password-strength__meter-container {
    &:after {
      background: url("data:image/svg+xml,%3Csvg width='2' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23f1f1f5' d='M-1-1h12v414H-1z'/%3E%3Cg/%3E%3C/svg%3E")
      16.6% 0 no-repeat,
      url("data:image/svg+xml,%3Csvg width='2' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23f1f1f5' d='M-1-1h12v414H-1z'/%3E%3Cg/%3E%3C/svg%3E")
      33.3% 0 no-repeat,
      url("data:image/svg+xml,%3Csvg width='2' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23f1f1f5' d='M-1-1h12v414H-1z'/%3E%3Cg/%3E%3C/svg%3E")
      50% 0 no-repeat,
      url("data:image/svg+xml,%3Csvg width='2' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23f1f1f5' d='M-1-1h12v414H-1z'/%3E%3Cg/%3E%3C/svg%3E")
      66.6% 0 no-repeat,
      url("data:image/svg+xml,%3Csvg width='2' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23f1f1f5' d='M-1-1h12v414H-1z'/%3E%3Cg/%3E%3C/svg%3E")
      83.3% 0 no-repeat;
    }
  }

  /* --- Bestseller paddings ---  */
  .bestseller__item:nth-child(2n) {
    .bestseller__link {
      @screen md {
        @apply pl-6;
      }
      @screen xl {
        @apply pl-0;
      }
    }
  }

  @screen xl {
    .bestseller__item:nth-child(3n + 2),
    .bestseller__item:nth-child(3n + 3) {
      .bestseller__link {
        @apply pl-6;
      }
    }
  }

  /* --- Bestseller accessibility fix ---  */
  .bestseller__listing {
    .bestseller__item:nth-child(n + 4) {
      visibility: hidden;
    }
    @screen md {
      .bestseller__item:nth-child(4) {
        visibility: visible;
      }
    }
    @screen xl {
      .bestseller__item:nth-child(4) {
        visibility: hidden;
      }
    }
  }
  .bestseller__listing.bestseller__listing-opened {
    .bestseller__item {
      visibility: visible !important;
    }
  }

  /* --- Dialog layouts fix --- */
  .dialog {
    .product-rating__header {
      flex-direction: column !important;
    }
  }
}

/* --- Progress bar colors --- */
.password-strength__meter {
  &::-webkit-progress-bar {
    @apply bg-gray-10;
  }
  &.color-1 {
    &::-webkit-progress-value{
      @apply bg-strength-1;
    }
  }
  &.color-2 {
    &::-webkit-progress-value {
      @apply bg-strength-2;
    }
  }
  &.color-3 {
    &::-webkit-progress-value {
      @apply bg-strength-3;
    }
  }
}


/* --- Availability colors  --- */
.availability--available {
  @apply text-stockAvailable;
}
.availability--unavailable {
  @apply text-stockUnavailable;
}
/* --- Order colors --- */
.border-blue {
  border-color: theme(colors.blue);
}

/* --- Tailwind nth-last-child fix --- */
.cart-page__sidebar-section {
  &:nth-last-child(2) {
    @apply border-none pb-0;
  }
}

.customer-request-money-refund--only-one {
  .customer-request-money-refund__radio {
    @apply hidden;
  }
}
.customer-request-return-method--only-one {
  .customer-request-return-method__radio {
    @apply hidden;
  }
}


/* THIRD SIDE COMPONENTS */

/* --- Swiper --- */
.swiper {
  @apply overflow-hidden w-full h-full;
}

.related-products {
  .swiper {
    /* overlay of dividing border on right */

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      background: white;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .swiper-slide {
    padding: 3rem 1.5rem;
  }
}

.swiper-wrapper {
  @apply flex w-full h-full;
}

.swiper-slide {
  @apply w-full h-full shrink-0;
}

.swiper-slide--product {
  @apply w-[12rem] shrink-0;
}

.swiper-button-lock,
.swiper-pagination-lock {
  @apply !hidden;
}

.swiper-pagination {
  @apply justify-center pt-8 gap-2 flex relative z-1;
}

.related-products {
  .swiper-pagination {
    @apply pt-0 hidden sm:flex;
  }
}

.product-gallery {
  .swiper-pagination {
    @apply absolute z-10 bottom-0 left-0 pt-0 mb-4 right-0 lg:hidden;
  }
}

.fullscreen-gallery {
  .swiper-slide {
    @apply flex justify-center items-center;
  }
}

.products-swiper {
  .swiper-slide {
    @apply !w-[12rem];
  }
}

.fullscreen-gallery__thumb {
  .swiper {
    max-width: 224px;
    margin: 0 auto;
  }

  @media only screen and (min-width: 400px) {
    .swiper {
      max-width: 302px;
    }
  }
  @media only screen and (min-width: 480px) {
    .swiper {
      max-width: 380px;
    }
  }

  .swiper-vertical {
    max-height: 468px;
  }

  @media only screen and (min-width: 1280px) {
    .swiper-vertical {
      max-height: 546px;
    }
  }
  @media only screen and (min-width: 1440px) {
    .swiper-vertical {
      max-height: 702px;
    }
  }
}

.swiper-vertical {
  @apply !h-auto;

  .swiper-wrapper {
    @apply flex-col;
  }

  .swiper-slide {
    @apply !h-auto p-0;
  }
}

/*  --- Product / fullscreen gallery active slide --- */
.product-gallery, .fullscreen-gallery {
  .product-gallery__thumb, .fullscreen-gallery__thumb {
    .swiper-slide-thumb-active {
      @apply !border-gray-50;
    }
  }
}

/* ---  Google Places Autocomplete ---
  https://developers.google.com/maps/documentation/javascript/place-autocomplete#style-autocomplete
*/
.pac-container {
  @apply border border-gray-20 shadow-horizontal-filter rounded-3xl mt-8 -translate-x-[5rem] p-0 after:hidden !w-[35.5rem];
}
.pac-icon {
  @apply hidden;
}
.pac-item {
  @apply p-form text-xs text-gray-60 cursor-pointer border-0 !font-sans;
}
.pac-item:hover {
  @apply bg-gray-10;
}
.pac-item-selected {
  @apply bg-gray-10;
}
.pac-item-query {
  @apply text-black font-medium text-sm block;
}
.pac-matched {
  @apply text-sm;
}

/* --- Recaptcha --- */
.grecaptcha-badge {
  visibility: hidden;
}

/* --- Cookie bar --- */
.cookie-bar--hidden {
  @apply opacity-0 invisible translate-y-full;
}
.cookie-bar--visible {
  @apply opacity-100 visible translate-y-0;
}
